import React, { useState, useEffect } from 'react';

const Controls = ({ setBackground, setAvatar, setAccessories, setCustomText, setShowTextElement, setCustomImage }) => {
  const [active, setActive] = useState({
    background: '/assets/backgrounds/background1.png',
    avatar: '',
    accessories: [], // Change to an array for multiple accessories
  });

  useEffect(() => {
    setBackground(active.background);
  }, [setBackground, active.background]);

  const toggleAccessory = (value) => {
    setActive((prev) => {
      const newAccessories = prev.accessories.includes(value)
        ? prev.accessories.filter((acc) => acc !== value)
        : [...prev.accessories, value];
      setAccessories(newAccessories); // Update the accessories state in parent
      return { ...prev, accessories: newAccessories };
    });
  };

  const toggleSelection = (type, value) => {
    setActive((prev) => ({
      ...prev,
      [type]: prev[type] === value ? '' : value,
    }));

    if (type === 'background') setBackground(active.background === value ? '/assets/backgrounds/background1.png' : value);
    if (type === 'avatar') setAvatar(active.avatar === value ? '' : value);
  };

  const handleTextChange = (e) => {
    setCustomText(e.target.value);
  };

  const handleAddText = () => {
    setShowTextElement(true);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setCustomImage(event.target.result); // Pass the uploaded image's data URL
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="controls">
      <h3 className="section-title">Backgrounds</h3>
      <div className="selector">
        {['background1.png', 'background2.png', 'background3.png', 'background4.png', 'background5.png'].map((bg, index) => (
          <img
            key={index}
            src={`/assets/backgrounds/${bg}`}
            onClick={() => toggleSelection('background', `/assets/backgrounds/${bg}`)}
            alt={`Background ${index + 1}`}
            className={active.background === `/assets/backgrounds/${bg}` ? 'active' : ''}
          />
        ))}
      </div>

      <h3 className="section-title">Avatars</h3>
      <div className="selector">
        {['major.png', 'major1.png', 'major2.png', 'major3.png', 'major4.png', 'major5.png', 'major6.png', 'major7.png', 'major8.png'].map((av, index) => (
          <img
            key={index}
            src={`/assets/avatar/${av}`}
            onClick={() => toggleSelection('avatar', `/assets/avatar/${av}`)}
            alt={`Avatar ${index + 1}`}
            className={active.avatar === `/assets/avatar/${av}` ? 'active' : ''}
          />
        ))}
      </div>

      <h3 className="section-title">Accessories</h3>
      <div className="selector">
        {['hat.png', 'left-eye.png', 'right-eye.png'].map((acc, index) => (
          <img
            key={index}
            src={`/assets/accessories/${acc}`}
            onClick={() => toggleAccessory(`/assets/accessories/${acc}`)}
            alt={`Accessory ${index + 1}`}
            className={active.accessories.includes(`/assets/accessories/${acc}`) ? 'active' : ''}
          />
        ))}
      </div>

      <div className="text-controls">
        <input
          type="text"
          onChange={handleTextChange}
          className="text-input"
          placeholder="Add Custom Text"
        />
        <button onClick={handleAddText} className="add-text-button">
          ADD
        </button>
      </div>

      <div className="upload-container">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          className="file-input"
          id="fileInput"
        />
        <label htmlFor="fileInput" className="upload-button">
          UPLOAD IMAGE
        </label>
      </div>
    </div>
  );
};

export default Controls;
