import { Connection, PublicKey } from '@solana/web3.js';

const RPC_URL = "https://mainnet.helius-rpc.com/?api-key=36b531fd-1995-4914-ba8b-8c196af49783"; // Use a reliable RPC provider
const SPL_TOKEN_ADDRESS = "CNyMaRR4krm1yAxfCvPXevNMArZZZk7sRqKwX68uCeJk";
const MIN_REQUIRED_TOKENS = 10;

const connection = new Connection(RPC_URL);

export async function checkTokenBalance(walletAddress) {
  try {
    // Fetch associated token accounts for the wallet
    const response = await connection.getParsedTokenAccountsByOwner(
      new PublicKey(walletAddress),
      {
        mint: new PublicKey(SPL_TOKEN_ADDRESS), // Specify the token's mint address
      }
    );

    if (response.value.length === 0) {
      console.warn("No associated token accounts found for the provided SPL token.");
      return false;
    }

    // Access the first token account and fetch balance details
    const tokenAccount = response.value[0];
    const balance = tokenAccount.account.data.parsed.info.tokenAmount.uiAmount; // UI-adjusted balance

    console.log(`Token Balance (uiAmount): ${balance}`); // Log for debugging

    // Return true if balance meets the required threshold
    return balance >= MIN_REQUIRED_TOKENS;
  } catch (error) {
    console.error("Error checking token balance:", error);
    return false;
  }
}
