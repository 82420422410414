import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Unauthorized from './components/Unauthorized';
import { WalletContextProvider } from './components/WalletContext';
import reportWebVitals from './reportWebVitals';

// Function to detect Brave and redirect
async function detectBraveAndRedirect() {
  const isBrave = navigator.brave && (await navigator.brave.isBrave());
  if (isBrave) {
    window.location.href = "https://brave.majorfrog.meme";
    return; // Prevent further execution if Brave
  }

  // Render the app
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <WalletContextProvider>
        <App />
      </WalletContextProvider>
    </React.StrictMode>
  );
}

// Call the function
detectBraveAndRedirect();

// For performance monitoring
reportWebVitals();
