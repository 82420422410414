import React, { useEffect, useState } from 'react';

const Terminal = () => {
  const [text, setText] = useState('');
  const messages = [
    'MAJOR FROG\nNASA is not to be trusted.\nNeil wouldn’t swear on the Bible.\nWhat are they hiding about Major Frog?\n ..>>>'
  ];

  useEffect(() => {
    let charIndex = 0;
    let currentText = '';

    const typingEffect = setInterval(() => {
      if (charIndex < messages[0].length) {
        currentText += messages[0][charIndex]; // Append the next character
        setText(currentText); // Update the state with the entire current string
        charIndex++;
      } else {
        clearInterval(typingEffect); // Stop when done typing
      }
    }, 50); // Adjust typing speed if needed

    return () => clearInterval(typingEffect); // Cleanup
  }, []);

  return <div className="terminal">{text}</div>;
};

export default Terminal;
