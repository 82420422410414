import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { checkTokenBalance } from './components/tokenVerification'; // Ensure this function is implemented
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faTerminal } from '@fortawesome/free-solid-svg-icons';
import Canvas from './components/Canvas';
import Controls from './components/Controls';
import Terminal from './components/Terminal';
import LinkSection from './components/LinkSection';
import './App.css';
import { Analytics } from "@vercel/analytics/react";

function App() {
  const { publicKey, connected, disconnect } = useWallet();
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state for token verification
  const [error, setError] = useState(null); // Error state for token validation

  // States for application functionality
  const [background, setBackground] = useState('/assets/backgrounds/background1.png');
  const [avatar, setAvatar] = useState('');
  const [accessories, setAccessories] = useState([]);
  const [customText, setCustomText] = useState('Your Text Here');
  const [showTextElement, setShowTextElement] = useState(false);
  const [customImage, setCustomImage] = useState(null);
  const [stageRef, setStageRef] = useState(null);

  useEffect(() => {
    const verifyAccess = async () => {
      if (connected && publicKey) {
        setLoading(true);
        try {
          const access = await checkTokenBalance(publicKey);
          setHasAccess(access);
          setError(access ? null : "You need at least 10 $MAJOR tokens to access the site.");
        } catch (err) {
          console.error('Error verifying token balance:', err);
          setError('Failed to verify token balance. Please try again.');
        } finally {
          setLoading(false);
        }
      }
    };

    verifyAccess();
  }, [connected, publicKey]);

  const handleDownload = () => {
    if (stageRef) {
      const transformers = stageRef.find('Transformer');
      transformers.forEach((transformer) => transformer.visible(false));

      const dataURL = stageRef.toDataURL({
        pixelRatio: 2,
        mimeType: 'image/png',
        quality: 1,
      });

      const link = document.createElement('a');
      link.download = 'major-builder.png';
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      transformers.forEach((transformer) => transformer.visible(true));
      stageRef.draw();
    } else {
      console.error('Stage reference not set');
    }
  };

  return (
    <WalletModalProvider>
      {/* Wallet connect button fixed to top-right */}


      {!connected || error ? (
        <div className="connect-wallet-screen">
          <div className="wallet-connect">
            <WalletMultiButton />
          </div>
          <h1>Welcome to the MajorFrog PFP Generator</h1>
          {!connected && <p>Please connect your wallet to proceed.</p>}
          {connected && loading && <p>Verifying your wallet...</p>}
          {connected && error && (
            <div className="error-message">
              <p>{error}</p>
              <button onClick={disconnect} className="disconnect-button">
                Disconnect Wallet
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="App">
          <header className="app-header">
            <div className="header-left">
              <h1>
                <FontAwesomeIcon icon={faGlobe} className="icon" /> MAJOR_LAB &gt; SUCCESSFULLY INITIALIZED
              </h1>
            </div>
            <div className="header-right">
              <span className="dev-info">
                DEV BY <a href="https://x.com/nifty_sol" target="blank">@NIFTY_SOL</a>
              </span>
              <span className="version">
              {connected && (
          <div className="wallet-status">
            <button onClick={disconnect} className="disconnect-button">
              Disconnect
            </button>
          </div>
        )}

              </span>
            </div>
          </header>


          <div className="main-layout">
            <div className="canvas-section">
              <div className="canvas-header">
                <div className="canvas-title">MAJOR_BUILDER</div>
                <div className="canvas-size">512x512</div>
              </div>
              <Canvas
                background={background}
                avatar={avatar}
                accessories={accessories}
                setAccessories={setAccessories}
                customText={customText}
                setCustomText={setCustomText}
                showTextElement={showTextElement}
                setShowTextElement={setShowTextElement}
                customImage={customImage}
                setCustomImage={setCustomImage}
                setStageRef={setStageRef}
              />
            </div>
            <div className="controls-section">
              <Controls
                setBackground={setBackground}
                setAvatar={setAvatar}
                setAccessories={setAccessories}
                setCustomText={setCustomText}
                setShowTextElement={setShowTextElement}
                setCustomImage={setCustomImage}
              />
            </div>
          </div>

          <div className="terminal-sep">
            <FontAwesomeIcon icon={faTerminal} /> Terminal
          </div>

          <footer className="footer">
            <Terminal />
            <LinkSection />
            <button onClick={handleDownload} className="download-button">
              Download Image
            </button>
          </footer>
          <Analytics />
        </div>
      )}
    </WalletModalProvider>
  );
}

export default App;
