import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';

const LinkSection = () => {
  return (
    <div className="links-section">
      <a href="https://themajorfrog.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGlobe} /> Website
      </a>
      <a href="https://x.com/themajorfrog" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faXTwitter} /> X (Twitter)
      </a>
      <a href="https://t.me/+8xiHi4n4iDFlNDk5" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTelegram} /> Telegram
      </a>
    </div>
  );
};

export default LinkSection;
