import React, { useRef, useEffect, useState } from 'react';
import { Stage, Layer, Image, Text, Transformer } from 'react-konva';
import useImage from 'use-image';

const DraggableImage = ({ src, isSelected, onSelect, onTransform, stageSize, initialWidth, initialHeight }) => {
  const [image] = useImage(src);
  const shapeRef = useRef();
  const trRef = useRef();
  const [initialSize, setInitialSize] = useState({ width: initialWidth || 100, height: initialHeight || 100 });

  useEffect(() => {
    if (image) {
      setInitialSize({
        width: initialWidth || image.width / 3,
        height: initialHeight || image.height / 3,
      });
    }
  }, [image, initialWidth, initialHeight]);

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Image
        image={image}
        draggable
        ref={shapeRef}
        width={initialSize.width}
        height={initialSize.height}
        onClick={onSelect}
        onTap={onSelect}
        onTransformEnd={() => {
          const node = shapeRef.current;
          onTransform({
            x: node.x(),
            y: node.y(),
            scaleX: node.scaleX(),
            scaleY: node.scaleY(),
            rotation: node.rotation(),
          });
        }}
      />
      {isSelected && <Transformer ref={trRef} />}
    </>
  );
};

const DraggableText = ({ text, isSelected, onSelect, onTransform }) => {
  const textRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([textRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Text
        text={text}
        fontSize={24}
        draggable
        ref={textRef}
        fill="#39ff14"
        fontFamily="IBM Plex Mono, monospace"
        onClick={onSelect}
        onTap={onSelect}
        onTransformEnd={() => {
          const node = textRef.current;
          onTransform({
            x: node.x(),
            y: node.y(),
            scaleX: node.scaleX(),
            scaleY: node.scaleY(),
            rotation: node.rotation(),
          });
        }}
      />
      {isSelected && <Transformer ref={trRef} />}
    </>
  );
};

const Canvas = ({
  background,
  avatar,
  accessories,
  setAccessories,
  customText,
  setCustomText,
  showTextElement,
  setShowTextElement,
  customImage,
  setCustomImage,
  setStageRef,
}) => {
  const [selectedId, setSelectedId] = useState(null);
  const [stageSize, setStageSize] = useState({ width: 512, height: 512 });

  const handleSelect = (id) => setSelectedId(id);

  const handleRemoveSelected = () => {
    if (!selectedId) return;

    if (selectedId.startsWith('accessory')) {
      const index = parseInt(selectedId.split('-')[1], 10);
      setAccessories((prev) => prev.filter((_, i) => i !== index));
    } else if (selectedId === 'customImage') {
      setCustomImage(null);
    } else if (selectedId === 'text') {
      setCustomText('');
      setShowTextElement(false);
    }

    setSelectedId(null);
  };

  useEffect(() => {
    const updateStageSize = () => {
      if (window.innerWidth <= 768) {
        setStageSize({ width: 380, height: 380 });
      } else {
        setStageSize({ width: 512, height: 512 });
      }
    };

    updateStageSize();
    window.addEventListener('resize', updateStageSize);
    return () => window.removeEventListener('resize', updateStageSize);
  }, []);

  return (
    <div>
      <Stage
        width={stageSize.width}
        height={stageSize.height}
        style={{ border: '1px solid black' }}
        ref={(node) => setStageRef(node)}
      >
        {/* Background Layer */}
        <Layer>
          <Image
            image={useImage(background)[0]}
            width={stageSize.width}
            height={stageSize.height}
          />
        </Layer>
        {/* Avatar Layer */}
        <Layer>
          {avatar && (
            <DraggableImage
              src={avatar}
              isSelected={selectedId === 'avatar'}
              onSelect={() => handleSelect('avatar')}
              onTransform={(newAttrs) => console.log('Avatar:', newAttrs)}
              stageSize={stageSize}
              initialWidth={stageSize.width}
              initialHeight={stageSize.height}
            />
          )}
        </Layer>
        {/* Custom Image Layer */}
        <Layer>
          {customImage && (
            <DraggableImage
              src={customImage}
              isSelected={selectedId === 'customImage'}
              onSelect={() => handleSelect('customImage')}
              onTransform={(newAttrs) => console.log('Custom Image:', newAttrs)}
              stageSize={stageSize}
            />
          )}
        </Layer>
        {/* Accessories Layer */}
        <Layer>
          {accessories.map((accessory, index) => (
            <DraggableImage
              key={index}
              src={accessory}
              isSelected={selectedId === `accessory-${index}`}
              onSelect={() => handleSelect(`accessory-${index}`)}
              onTransform={(newAttrs) => console.log(`Accessory-${index}:`, newAttrs)}
              stageSize={stageSize}
            />
          ))}
        </Layer>
        {/* Text Layer */}
        <Layer>
          {showTextElement && (
            <DraggableText
              text={customText}
              isSelected={selectedId === 'text'}
              onSelect={() => handleSelect('text')}
              onTransform={(newAttrs) => console.log('Text:', newAttrs)}
            />
          )}
        </Layer>
      </Stage>
      {['accessory', 'customImage', 'text'].some((type) => selectedId?.startsWith(type)) && (
        <button onClick={handleRemoveSelected} className="delete-button">
          Delete Selected
        </button>
      )}
    </div>
  );
};

export default Canvas;
